
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const LoginPopup = {

    init: function() {

        this.$popup = $('[data-login-popup]');
        this.$toggle = $('[data-login-popup-toggle]');
        this.$dismiss = $('[data-login-popup-dismiss]');

        if ( !this.$popup.length || !this.$toggle.length || !this.$dismiss.length )
            return;

        this.active = this.$popup.hasClass('is-active');
        this.$toggle.on( 'click', this.setActive.bind(this) );
        this.$dismiss.on( 'click', this.setActive.bind(this) );

        this.removeLink();

        this.$form = this.$popup.find('[data-login-popup-type]');

        if ( !this.$form.length )
            return;

        this.$reveal = $('[data-login-popup-password-reveal]');

        if ( this.$reveal.length )
            this.$reveal.on( 'click', this.togglePassword.bind(this) );

        this.$form.on( 'submit', this.ajaxLogin.bind(this) );
        
        // $( document ).ajaxComplete( this.convertErrorMessage.bind(this) );

    },

    // convertErrorMessage( event, xhr, settings ) {

    //     if ( 'registration-error-email-exists' == xhr.responseJSON.code ) {
    //         var $message = xhr.responseJSON.message;
    //         $('.register_msg.fail').html($message).text(); 
    //     }
        
    // },

    ajaxLogin( e ) {

        let $form = $(e.currentTarget);
        let $type = $form.data('login-popup-type');

        $('[data-login-popup-message]').hide();
        
        let $message = $form.find('[data-login-popup-message]');
        let $username = $form.find('[data-login-popup-username]');
        let $password = $form.find('[data-login-popup-password]');
        let $remember = $form.find('[data-login-popup-remember]');
        let $security = $form.find('#security');
        let $redirect = $form.find('[data-login-popup-redirect]');
        let $action = $form.find('[data-login-popup-action]');

        if ( !$password.val().length || !$username.val().length ) {
            $message.addClass('is-error');

            if ( $type == 'register' ) {
                $message.show().html( 'Email and password is required.' );
            } else {
                $message.show().html( 'Username/Email and password is required.' );
            }

            return;
        }

        $message.show().html( ajax_login_object.loading_message );
        $message.removeClass('is-success');
        $message.removeClass('is-error');
        $username.removeClass('is-error');
        $password.removeClass('is-error');

        let $data = {
            'action' : 'ajaxlogin',
            'username' : $username.val(),
            'password' : $password.val(),
            'security' : $security.val(),
            'user_action' : $action.val()
        }

        if ( $remember.length )
            $data['remember_me'] = ( $remember.is( ':checked' ) ? true : false );

        $.ajax( {
            type: 'POST',
            dataType: 'json',
            url: ajax_login_object.ajax_url,
            data: $data,
            success: ( data ) => {
                $message.html( data.message );

                if ( data.is_error ) {
                    $message.addClass('is-error');
                } else {
                    $message.addClass('is-success');
                }

                if ( data.logged_in == true ) {
                    document.location.href = $redirect.val();
                }
            },
            error: ( data ) => {
                $username.addClass('is-error');
                $password.addClass('is-error');
                $message.addClass('is-error');

                if ( data.status == 200 ) {
                    $message.html( ajax_login_object.incorrect_login );
                } else if ( data.status == 400 ) {
                    $message.html( 'Something went wrong, try <a href="' + ajax_login_object.admin_url + '">login screen</a> instead.' );
                } else {
                    $message.html( data.statusText );
                }
            }
        } );

        e.preventDefault();

    },

    togglePassword( e ) {

        let $button = $(e.currentTarget);
        let $password = $button.prev();

        if ( $password.attr('type') == 'password') {
            $password.attr('type', 'text');
            $button.html('Hide');
        } else {
            $password.attr('type', 'password');
            $button.html('Show');
        }

    },

	setActive() {

        this.active = !this.active;
        this.$popup.fadeToggle();

        if ( this.active ) {
            disableBodyScroll( this.$popup[0] );
        } else {
            enableBodyScroll( this.$popup[0] );
        }

	},

    removeLink() {

        this.$toggle.removeAttr('href');
        
    }
        
};

export default LoginPopup;