
export const Slider = {

    init: function() {

        this.$slider = $('[data-slider]');

        if ( !this.$slider.length )
            return;

        this.$slider.slick({
            autoplay : true,
            autoplaySpeed: 5000,
            arrows : true,
            dots : false,
            cssEase: 'ease-out',
            prevArrow: $('[data-slider-prev'),
            nextArrow: $('[data-slider-next')
        });

    }
        
};

export default Slider;