
export const ProductFilters = {

    init: function() {

        this.$filters = $('[data-product-filters]');
        this.$toggle = $('[data-product-filters-toggle]');

        if ( !this.$filters.length || !this.$toggle.length )
            return;

        this.$filters.hide();

        this.$toggle.on( 'click', this.setActive.bind(this) );

        document.addEventListener( 'facetwp-loaded', this.hideEmptyFacets.bind(this) );

    },

    setActive() {

        this.active = !this.active;

        if ( this.active ) {
            this.$toggle.html( 'Hide Filters' );
        } else {
            this.$toggle.html( 'Show Filters' );
        }

        this.$filters.slideToggle();

	},

    hideEmptyFacets() {
        
        $.each(FWP.settings.num_choices, function(key, val) {
            var $facet = $('.facetwp-facet-' + key);
            var $parent = $facet.closest('.facet-wrap');
            var $flyout = $facet.closest('.flyout-row');
            if ($parent.length || $flyout.length) {
                var $which = $parent.length ? $parent : $flyout;
                (0 === val) ? $which.hide() : $which.show();
            }
        });

        var urlParams = new URLSearchParams(window.location.search);

        $('input[name="_sort_by"]').val( urlParams.get('_sort_by') );
        $('input[name="_category"]').val( urlParams.get('_category') );
        $('input[name="_availability"]').val( urlParams.get('_availability') );
        $('input[name="_price"]').val( urlParams.get('_price') );
    }

};

export default ProductFilters;