
window.$ = window.jQuery;

import '../styles/app.scss';

import { Site } from './modules/site';
import { LoginPopup } from './modules/loginPopup';
import { MobileMenu } from './modules/mobileMenu';
import { Slider } from './modules/slider';
import { ProductFilters } from './modules/productFilters';

Site.init();
MobileMenu.init();
LoginPopup.init();
Slider.init();
ProductFilters.init();